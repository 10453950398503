import React from "react"
import Layout from "../../components/Layout"
import PartnerCarousel from "../../components/PartnerCarousel"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleRight,
  faWalking,
  faGraduationCap,
  faLaptop,
  faAddressBook,
  faPeopleCarry,
  faUser,
  faPiggyBank,
  faWifi,
} from "@fortawesome/free-solid-svg-icons"
import { Banner } from "../../components/Banner"
import Services from "../../components/Services"
import EducationOffers from "../../components/education/EducationOffers"
import SEO from "../../components/seo"
import Accordions from "../../components/Accordion"
import SectionTitle from "../../components/SectionTitle"

export default function Networks() {
  const benefits = [
    {
      title: "True mobility",
      desc:
        "Be anywhere within the wireless footprint and have access at any time to the network via wireless connectivity. Link prefabricated or remote buildings to the network without the use of expensive and unsightly wiring.",
      icon: <FontAwesomeIcon icon={faWalking} />,
    },
    {
      title: "Flexible exam locations",
      desc:
        "Exam location is no longer confined to one room – with laptops for example, any room or location within the wireless coverage area can be quickly assigned as an examination room.",
      icon: <FontAwesomeIcon icon={faLaptop} />,
    },
    {
      title: "Easy equipment relocation",
      desc:
        "IT equipment is no longer fixed to the desk it is sat on or the ethernet point it is plugged into. Equipment can be relocated anywhere you wish, providing you with freedom and flexibility to increase your learning space.",
      icon: <FontAwesomeIcon icon={faPeopleCarry} />,
    },
    {
      title: "Reduced support costs",
      desc:
        "Reduced amounts of cabling and ethernet points. Future expansion or relocation of departments/classes doesn’t entail new cabling or new ethernet points to be installed. This reduces the cost of maintenance contracts as there is less to support and less chance of network/equipment failure.",
      icon: <FontAwesomeIcon icon={faPiggyBank} />,
    },
    {
      title: "Increase rate of learning",
      desc:
        "Encourages children to use mobile equipment more often especially in the library, quiet zones etc. It’s a proven fact that this increases the rate of learning.",
      icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
      title: "E-Registration",
      desc:
        "Register students anywhere within the school using any wireless device (laptop, PDA, tablet etc)",
      icon: <FontAwesomeIcon icon={faAddressBook} />,
    },
    {
      title: "Quick class changes",
      desc:
        "No classes are ever out of action. A class of children/students can literally be relocated within minutes to a spare room in cases of emergency (i.e. heating malfunction) and teaching can continue as normal using the wireless network.",
      icon: <FontAwesomeIcon icon={faUser} />,
    },
    {
      title:
        "Easy expansion to new services over your existing wireless infrastructure",
      desc: (
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          <li>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="#2d4a8a"
              size="sm"
            />
            {"  "}
            <span>VoIP</span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="#2d4a8a"
              size="sm"
            />
            {"  "}
            <span>Wireless CCTV</span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="#2d4a8a"
              size="sm"
            />
            {"  "}
            <span>Wireless Asset Tagging</span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="#2d4a8a"
              size="sm"
            />
            {"  "}
            <span>Secure Visitor Access Control</span>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="#2d4a8a"
              size="sm"
            />
            {"  "}
            <span>Classroom Interactivity through Touchscreens</span>
          </li>
        </ul>
      ),
      icon: <FontAwesomeIcon icon={faWifi} />,
    },
  ]

  const data = useStaticQuery(graphql`
    query educationNetworks {
      WirelessNetworks: file(
        relativePath: { eq: "services/AdobeStock_250877262.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      NetworkSecurity: file(
        relativePath: { eq: "services/AdobeStock_46647398.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Printers: file(
        relativePath: { eq: "services/AdobeStock_255540819.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Wireless Networks",
      description:
        "Access Points, Outdoor Access Points, Wireless Controllers, Routers, Fibre Optic",
      image: data.WirelessNetworks.childImageSharp.fixed,
    },
    {
      title: "IT Networks",
      description:
        "Servers, Storage, Cabling, Switches, Network and Data Points, UPS",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Network Security",
      description: "Web Filtering, Antivirus, Server Racks, Comms Cabinets",
      image: data.NetworkSecurity.childImageSharp.fixed,
    },
    {
      title: "Printers",
      description:
        "Mono Laser Printers, Colour Laser Printers, Multifunction Printers, Inkjet Printers",
      image: data.Printers.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Networks Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="Make your school a wireless environment"
              title="Networks"
              description={
                <>
                  <p>
                    The computer network has become the heartbeat of every
                    teaching environment and it supports every facet of the
                    teaching and learning experience.
                  </p>
                  <p>
                    It’s therefore critical that any education network is
                    designed with future proofing in mind so it can incorporate
                    ongoing software developments.
                  </p>
                  <p>
                    Speed, security, storage and reliability are just four of
                    the key elements required in any design so the teaching
                    experience can be delivered; anywhere, any time and any
                    place.
                  </p>
                  <p>
                    Compulease™ are able to help you plan, manage and implement
                    or upgrade your network technology to improve teaching,
                    learning, safeguarding and your school community.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="text-center">
              Benefits of having a modern wireless network in your School or
              College
            </h3>
          </Col>
        </Row>
        <Accordions items={benefits} />
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Find the right solution for your institution"
          description="Compulease™ will ensure you install the correct equipment from the market leading product manufacturers and integrators for a reliable and high-performance network. So talk to us now
          about how we can help you to enhance your teaching environment by leasing a wireless network."
          link={{
            text: "Speak to our friendly customer service team",
            to: "/education/ContactUs/",
          }}
        />
        <Services title="Solutions we provide" services={services} />
      </Container>
      <PartnerCarousel type="networks" />
    </Layout>
  )
}
